import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const TeamList = () => {
  return (
    <LayoutComponent>
      <h2>Project Team</h2>

      <div>
        <p>
          On the <strong>Project Team</strong> page, both the organization admin
          and project admin can manage team members assigned to a project. This
          includes adding users, assigning roles, and removing users from the
          project.
        </p>

        <h6>Managing Team Members</h6>
        <ol>
          <li>
            Navigate to the project for which you want to manage team members.
          </li>
          <li>
            In the project sidebar, click on <strong>Project Team</strong> to
            access the team management page.
          </li>
        </ol>

        <h6>Assigning Users to a Project</h6>
        <ol>
          <li>
            On the Project Team page, click on <strong>Add User</strong> to
            assign a new team member to the project.
          </li>
          <li>Select from the existing list of users in your organization.</li>
          <li>
            Choose the role for the user from the available options (e.g.,{" "}
            <strong>Member</strong>, <strong>Admin</strong>).
          </li>
          <li>
            Click <strong>Assign</strong> to complete the process. The user will
            now have access to the project with the assigned role.
          </li>
        </ol>

        <h6>Removing Users from a Project</h6>
        <ol>
          <li>
            To remove a user from a project, locate the user in the team list.
          </li>
          <li>
            Click on the <strong>Remove</strong> option next to the user’s name.
          </li>
          <li>
            Confirm the removal. The user will no longer have access to the
            project.
          </li>
        </ol>

        <h6>Roles and Permissions</h6>
        <p>
          Each user in the project team can be assigned a specific role, which
          dictates their level of access and permissions within the project.
          Admins have full control to modify these roles at any time.
        </p>
      </div>
    </LayoutComponent>
  )
}

export default TeamList
